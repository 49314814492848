import React from 'react';

import './Resume.css'

//set up the stuff and css using this
//https://stackoverflow.com/questions/796087/make-a-div-into-a-link

function Resume(props:{text: string; link: string; class: string} ) {
    return(
        <a type="button" className={props.class} href={props.link}>{props.text}</a>
    )
}

export default Resume;

