//Copyright © Yuxi Qin - Check me out at yuxiqin.ca
// @ts-ignore
import React from 'react';// Require context image folder

import './Card.css';
import Tag from './Tag'

function Card(props: { image: string; title: any; tagline: any; tags: any | undefined; }) {
    const image = props.image,
        title = props.title,
        tagline = props.tagline,
        tagArr = props.tags,
    imgPath = '/images/' + image;

    //<img src={require(`${imgPath}`)} />
    //find a way to dynamically import images
    return (
        <div className="topic-card">
            <div className="thumbnail">
                <div className="thumbnail-inner" style={{ backgroundImage: `url("${imgPath}")`}}/>

            </div>

            <div className="topic-info">
                <div className="topic-heading-info">
                    <p className="title">{title}</p>
                    <p className="tagline">{tagline}</p>
                </div>
                <div className="tags">{

                    //takes each individual tag found in the JSON file and turns it into a stylized div
                    tagArr.map(function (tag: string) {
                        var className = tag + "-tag";
                        return <Tag classes={className} tag={tag}/>
                    })
                }
                </div>

            </div>

        </div>
    )
}


export default Card;
