import React from 'react';


import './App.css';

import Splash from './components/Splash'
import Topic from './components/Topic'

function App() {
    return (
        <div className="App">
            <Splash/>
            <Topic section="highlights" header="Highlights"/>
            <Topic section="experience" header="Experience"/>
            <Topic section="project" header="Projects"/>
        </div>
    );
}

export default App;
