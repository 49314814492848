//Copyright © Yuxi Qin - Check me out at yuxiqin.ca
import React from 'react';

import './Modal.css';
import Linkbtn from "./Linkbtn";
import Tag from "./Tag";
import Description from "./Description";

function Modal(props: { image: string; section: string; name: string; tagline: string; start: string; end: string; links: any; description: any; tags: any; }) {
    var image = props.image,
        section = props.section,
        name = props.name,
        tagline = props.tagline,
        start = props.start,
        end = props.end,
        linksArr = props.links,
        descriptionArr = props.description,
        tagArr = props.tags;

    var imgPath = '/images/' + image;

    //https://github.com/Vibrant-Colors/node-vibrant

    //calculating and displaying the duration of time spent on a project/experience

    //next step - make code cleaner and implement string substitution instead of the janky + signs
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var msDiff, dayDiff, monthDiff, startMo, endMo, timeline, duration,
        startDate = new Date(start);

    //first 3 letters of month + year
    startMo = monthNames[startDate.getMonth()].substring(0,3) + " " + new Date(start).getFullYear();
    if (end === "present"){
        msDiff = new Date().getTime() - startDate.getTime() // current date - past date
        endMo = "Present"
    } else {
        var endDate = new Date(end);
        msDiff = endDate.getTime() - new Date(start).getTime();
        endMo = monthNames[endDate.getMonth()].substring(0,3) + " " + endDate.getFullYear();
    }
    monthDiff = Math.round(msDiff / (1000 * 60 * 60 * 24 * 30)); //duration of the project/exp in MONTHS
    dayDiff = Math.round(msDiff / (1000 * 60 * 60 * 24)); // duration of the project/exp in days

    timeline = startMo + " - " + endMo;
    duration =  "  •  " + monthDiff + " mo";

    if (dayDiff <= 4){ //if it was a weekend project/exp
        timeline = startMo;
        duration = "  •  1 weekend"
    } else if (dayDiff <= 30){ //if it was a <1 month project/exp
        timeline = startMo;
        duration = "  •  <1 mo"
    } else if (monthDiff > 12){
        var years = Math.floor(monthDiff/12),
            months = monthDiff%12;
        duration = "  •  " + years + " yr " +  months +" mo";
    } else if (monthDiff === 12){
        var years = Math.floor(monthDiff/12);
        duration = "  •  " + years + " yr ";
    }

    return (
        <div className="modal-container">
            <div className="thumbnail-info-wrapper row">
                <div className="topic-cover col col-4">
                    <div className="cover-inner" style={{ backgroundImage: `url("${imgPath}")`}}/>
                </div>
                <div className="topic-header-info col col-8">
                    <div className="topic-header-info-inner">
                        <p className="section-text">{section}</p>
                        <div className="name-tagline-wrapper">
                            <p className="topic-name">{name}</p>
                            <p className="tagline-text">{tagline}</p>
                        </div>
                        <div className="timeline">
                            <p className="timeline-text">{timeline}</p>
                            <p className="duration-text">{duration}</p>
                        </div>
                        <div className="topic-links">
                            {
                                Object.keys(linksArr).map((key)=> (
                                    <Linkbtn content={key} link={linksArr[key]} type={key}/>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-description">
                {
                    Object.keys(descriptionArr).map((key)=> (
                        <Description type={key} content={descriptionArr[key]} />
                    ))
                }
            </div>
            <div className="tags">
                {
                    //takes each individual tag found in the JSON file and turns it into a stylized div
                    tagArr.map( function(tag: string) {
                        var className=tag + "-tag";
                        return <Tag classes={className} tag={tag}/>
                    })
                }
            </div>
        </div>

    )
}

export default Modal;