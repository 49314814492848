import React from 'react';

import './Tag.css'
function Tag(props: { classes: any; tag: any; }) {

    // var str = "Sonic Free Games";
    // str = str.replace(/\s+/g, '-').toLowerCase();
    // console.log(str); // "sonic-free-games"
    var classes = props.classes,
        content = props.tag;
    return(
        <div className={classes}>{content}</div>
    )
}

export default Tag;