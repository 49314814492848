
import './TextCarousel.css'
import React from "react";
import Typewriter from 'typewriter-effect';

// https://mattboldt.github.io/typed.js/docs/
// this looks like it's no longer supported??? just throws an error whenver i try to use it
// look into implementing this one instead
// https://npm.io/package/typewriter-effect

function TextCarousel() {
    return (
        <div className="typing-carousel-wrapper">
            <Typewriter
                options={{
                    strings: ["She/They","Computer Engineering Student", "Test Engineer", "DAQ Lead", "Hackathon Organizer", "Web Developer", "UI/UX Designer"],
                    autoStart: true,
                    loop: true,
                    delay: 120,
                    deleteSpeed: 80,
                }}
            />
        </div>
    )
}

export default TextCarousel