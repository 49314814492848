import React from 'react';

import './Linkbtn.css'


function Linkbtn(props: { content: any; link: any; type: any; }) {
    var content = props.content,
        link = props.link,
        btnType = props.type,
        classes = "modal-link-btn";

    if (btnType !== "view") {
        classes += " other-btn"
    } else {
        classes += " main-btn"
    }

    return(
        <a type="button" className={classes} href={link} target="_blank" rel="noreferrer noopener">{content}</a>
    )
}

export default Linkbtn;