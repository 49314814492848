// @ts-ignore
import React from 'react';

import './Socials.css';

function Socials() {
    return (
        <ul className="socials">
            <li className="social-object fa-stack fa-2x">
                <a href="https://go.yuxiqin.ca/linkedin" rel="noopener noreferrer">
                    <i className="fa fa-circle fa-stack-2x icon-background"/>
                    <i className="fab fa-linkedin-in fa-stack-1x social-icon"/>
                </a>
            </li>

            <li className="social-object fa-stack fa-2x">
                <a href="https://go.yuxiqin.ca/github" rel="noopener noreferrer">
                    <i className="fa fa-circle fa-stack-2x icon-background"/>
                    <i className="fab fa-github fa-stack-1x social-icon"/>
                </a>
            </li>

            <li className="social-object fa-stack fa-2x">
                <a href="https://go.yuxiqin.ca/instagram" rel="noopener noreferrer">
                    <i className="fa fa-circle fa-stack-2x icon-background"/>
                    <i className="fab fa-instagram fa-stack-1x social-icon"/>
                </a>
            </li>

            <li className="social-object fa-stack fa-2x">
                <a href="mailto:hello@yuxiqin.ca" rel="noopener noreferrer">
                    <i className="fa fa-circle fa-stack-2x icon-background"/>
                    <i className="fas fa-envelope fa-stack-1x social-icon"/>
                </a>
            </li>

        </ul>
    )
}

/* <i className="fab fa-spotify"></i>
http://go.yuxiqin.ca/spotify
 */

export default Socials;