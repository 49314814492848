//Copyright © Yuxi Qin - Check me out at yuxiqin.ca
import React from 'react';
import ReactMarkdown from 'react-markdown'

import './Description.css'

// when adding different paragraphs/text things, just add an additional space cus that's technically a different key and i'm lazy
function Description(props: { type: any; content: any; }){
    var type = props.type.trim(),
        content = props.content,
        imgPath;

    if (type === "markdown" || type === "text"){
        return(
            <ReactMarkdown>{content}</ReactMarkdown>
        )
    } else if (type === "image") {
        imgPath = '/images/description/' + content;
        return (<div className="descrip-image-wrapper">
                    <div className="descrip-image" style={{backgroundImage: `url("${imgPath}")`}}/>
                </div>
        )

    } else if (type === "video") {
        return (
            <div className="yt-container">
                <iframe src={`https://www.youtube-nocookie.com/embed/${content}`}
                        className="yt-video"
                        title="YouTube video player" frameBorder="0"
                        allow="autoplay; encrypted-media;"
                        allowFullScreen/>
            </div>
        )
    } else if (type === "3d-embed"){
        return (
            <div className="sketchfab-embed-wrapper embed-container">
                <iframe
                    src={content}
                    className="embed-model"
                    title="3D Embed"
                    allow="autoplay; fullscreen; xr-spatial-tracking"
                />

            </div>
        )
    } else if (type === "pdf-embed"){
        return (
            <div className="embed-container">
                <iframe
                    src={content}
                    className="embed-pdf"
                    />
            </div>
        )
    }
         else {
        return (<div/>)
    }
}

//look into imgur embedding - blockquote fix - https://stackoverflow.com/questions/60577345/imgur-embed-does-not-show-image-in-angular-web-app
export default Description;