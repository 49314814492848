//Copyright © Yuxi Qin - Check me out at yuxiqin.ca
import React, {useState} from 'react';

// Firebase App (the core Firebase SDK) is always required and must be listed first
//import 'firebase/analytics'
// import firebase from "firebase/app";

// Add the Firebase products that you want to use
//import "firebase/auth";
// import "firebase/firestore";

import Swal2 from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ContentInfo from '../docs/content.json'

import './Topics.css';
import Card from './parts/Card'
import Modal from './parts/Modal'

const Swal = withReactContent(Swal2)

// integrate card click thing
function Topic(props: { section: any; header: any}) {
    var section = props.section,
        header = props.header;
    var isOnDesktop = window.innerWidth > 576;
    var [isOn, toggleIsOn] = useState(false) // toggle seemore/seeless button
    console.log(isOn)
    //https://reactjs.org/docs/animation.html
    //add animation so that the toggle buttons arent weird ;-;

    // const analytics = props.firebaseInst;


    //sorting the things by date
    function custom_sort(a: { end: string | number | Date; }, b: { end: string | number | Date; }) {
        var a_newEnd = (a.end === "present") ? (new Date().getTime()) : (a.end);
        var b_newEnd = (b.end === "present") ? (new Date().getTime()) : (b.end);
        return new Date(b_newEnd).getTime() - new Date(a_newEnd).getTime();
    }
    ContentInfo.sort(custom_sort);

    var sectionContent = ContentInfo.filter(a => a.section === section);
    sectionContent = (!isOn && isOnDesktop)? sectionContent.slice(0,6): sectionContent


    return (
        <section id={section} className="topic-wrapper">
            <div className="topic-header">{header}</div>
            <div className="card-wrapper">
                {sectionContent.map((topicInfo) => (
                        <div id={topicInfo.projID} className={`topic-card-container ${topicInfo.tags.join(" ")}`} onClick={ () => {
                            //analytics.logEvent("test")
                            // analytics.logEvent('open_topic_card', {
                            //     content_type: section,
                            //     content_id: topicInfo.name
                            // });

                            var modalContent =
                                <Modal image={topicInfo.image}
                                       section={topicInfo.section}
                                       name={topicInfo.name}
                                       tagline={topicInfo.tagline}
                                       start={topicInfo.start}
                                       end={topicInfo.end}
                                       links={topicInfo.links}
                                       description={topicInfo.description}
                                       tags={topicInfo.tags}
                                />;


                            return (
                                Swal.fire({
                                    width: 900,
                                    background: 'linear-gradient(180deg, rgba(94, 217, 206, 0.5) 0%, rgba(255, 255, 255, 0) 30%), #191919',
                                    html: modalContent,
                                    showConfirmButton: false,
                                    showCloseButton: true,
                                    showCancelButton: false,

                                })
                            )

                        }}>
                            <Card
                                image={topicInfo.image}
                                title={topicInfo.name}
                                tagline={topicInfo.tagline}
                                tags={topicInfo.tags}
                            />
                        </div>
                    )
                )}
            </div>

            {/*<div className="seemore-btn-wrapper seemore-btn">*/}
            {/*    <button onClick={()=>{*/}
            {/*        toggleIsOn(prevState => !prevState)*/}
            {/*    }}>*/}
            {/*        {isOn? "See less": "See more"}</button>*/}
            {/*</div>*/}
        </section>

    )
}

export default Topic;